import { Link } from "react-router-dom";
import fambino from '../media/img/logo.png';
import React, { useState, useEffect } from 'react';
import '../style/trips.css';
import { API, Storage } from 'aws-amplify';
import { listNotes } from '../graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from '../graphql/mutations';
import Amplify from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);


//Amplify.configure({
//  ...config,
//  Analytics: {
//    disabled: true,
//  },
//});

const initialFormState = { name: '', description: '' }

function Trips() {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
    const apiData = await API.graphql({ query: listNotes });
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(notesFromAPI.map(async note => {
      if (note.image) {
        const image = await Storage.get(note.image);
        note.image = image;
      }
      return note;
    }))
    setNotes(apiData.data.listNotes.items);
  }

  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    if (formData.image) {
      const image = await Storage.get(formData.image);
      formData.image = image;
    }
    setNotes([ ...notes, formData ]);
    setFormData(initialFormState);
  }

  async function deleteNote({ id }) {
    const newNotesArray = notes.filter(note => note.id !== id);
    setNotes(newNotesArray);
    await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
  }

  async function onChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);
    fetchNotes();
  }
  
  /*function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
    return file && acceptedImageTypes.includes(file['type'])
  }*/

  return (
    <div className="App">
      <div className="navbars">
        <div className="p-6 container-md" style={{marginTop: '0px'}}>
          <form className="mb-1 w-100 mx-auto mb-auto">
            <header className="p-1 container-md" id="containered">
              <Link to="/home">
                <img id="fambino" src={fambino} alt="fambino"/>
              </Link>
            </header>
          </form>
        </div>
	    </div>
      <h1 style={{color: 'white'}}>Trips</h1>
      <input
        onChange={e => setFormData({ ...formData, 'name': e.target.value})}
        placeholder="Note name"
        value={formData.name}
      />
      <input
        onChange={e => setFormData({ ...formData, 'description': e.target.value})}
        placeholder="Note description"
        value={formData.description}
      />
      <input
        type="file"
        onChange={onChange}
      />
      <button onClick={createNote} style={{color: 'white'}}>Create Post</button>
      <div style={{marginBottom: 0}}>
        {
          notes.map(note => (
            <div key={note.id || note.name}>
              <br />
              <br />
              <h2 style={{color: 'white'}}>{note.name}</h2>
              <p style={{color: 'white'}}>{note.description}</p>
              <button onClick={() => deleteNote(note)}>Delete</button><br />
                {note.image && <video poster={note.image} src={note.image+"#t=0.1"} style={{width: 700}} alt="..." controls/>}
            </div>

          ))
        }
      </div>
    </div>
  );
}

export default Trips;




/*export class Trips extends React.Component {
     render() {
          return( <>
               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
	          </div>
          </>);
     }
}*/