import React from 'react';
import 'react-router-dom';
import '../style/start.css';
import { Link } from "react-router-dom"
// import Fade from '@material-ui/core/Fade';
import polaroid from "../media/img/polaroid.png";

export class Start extends React.Component {

     render() {
          return (<>
               <div className="layer" id="layer2">
                    <div className="w3-center w3-row-padding">
                         <div className="w3-btn w3-black w3-ripple w3-padding-xxxlarge">
                              {/*<Fade in="true" style={{transitionDelay:'1000ms'}}>*/}
                                   <Link to="/home">
                                        <img src={polaroid} style={{width:"100%"}} alt="Polaroid"/>
                                   </Link>
                              {/*</Fade>*/}
                         </div>
                    </div>
               </div>
          </>)
     }
}