import React from 'react';
import './users/users.css';
import '../style/about-us.css';
import { Link } from "react-router-dom";
import fambino from '../media/img/logo.png';
import joseImg from '../media/pfp/jose-AboutUs.jpg'; 
import kajeImg from '../media/pfp/kaje-AboutUs.jpg'; 
import markImg from '../media/pfp/mark-AboutUs.jpg'; 
import nickImg from '../media/pfp/nick-AboutUs.jpg'; 
import stevenImg from '../media/pfp/steven-AboutUs.jpg'; 
import brandonImg from '../media/pfp/brandon-AboutUs.jpg'; 

export class AboutUs extends React.Component {
    render() {
        return( <>
            <div className="navbars">
                <div className="p-6 container-md" style={{marginTop: '0px'}}>
                     <form className="mb-1 w-100 mx-auto mb-auto">
                          <header className="p-1 container-md" id="containered">
                               <Link to="/home">
                                    <img id="fambino" src={fambino} alt="fambino"/>
                               </Link>
                          </header>
                     </form>
                </div>
	        </div>
            <div className="p-6 container-md">
                <div className="l-container" id="l-container">
                    <div className="mod-container"> <Link to="/Jose"><img src={joseImg} alt="Jose" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Jose">Jose</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/ogys"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                    <div className="mod-container"> <Link to="/Kaje"><img src={kajeImg} alt="Kaje" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Kaje">Kaje</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/_Fortney"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                    <div className="mod-container"> <Link to="/Mark"><img src={markImg} alt="Mark" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Mark">Mark</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/FatGoats"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                    <div className="mod-container"> <Link to="/Nick"><img src={nickImg} alt="Nick" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Nick">Nick</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/Nick_Dodge"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                    <div className="mod-container"> <Link to="/Steven"><img src={stevenImg} alt="Steven" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Steven">Steven</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/st_evn"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                    <div className="mod-container"> <Link to="/Brandon"><img src={brandonImg} alt="Brandon" /></Link>
                        <figcaption>
                            <h3 style={{fontFamily: "'Permanent Marker', cursive"}}><Link style={{color: 'white'}} to="/Brandon">Brandon</Link></h3>
                            <div className="icons">
                                <a href="https://instagram.com/GreatestBrandon"> <i className="ion-social-instagram"></i></a>
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

            {/*<div className="p-4 container-md">
                <div className="l-container" id="l-container">
                    <div className="b-game-card">
                        <Link to="/Jose">
                            <div className="b-game-card__cover">
                                <img id="avis" src={joseImg} alt="Jose"/>
                                <h2 className="card-title">Jose</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="b-game-card">
                        <Link to="/Kaje">
                            <div className="b-game-card__cover">
                                <img id="avis" src={kajeImg} alt="Kaje"/>
                                <h2 className="card-title">Kaje</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="b-game-card">
                        <Link to="/Mark">
                            <div className="b-game-card__cover">
                                <img id="avis" src={markImg} alt="Mark"/>
                                <h2 className="card-title">Mark</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="b-game-card">
                        <Link to="/Nick">
                            <div className="b-game-card__cover">
                                <img id="avis" src={nickImg} alt="Nick"/>
                                <h2 className="card-title">Nick</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="b-game-card">
                        <Link to="/Steven">
                            <div className="b-game-card__cover">
                                <img id="avis" src={stevenImg} alt="Steven"/>
                                <h2 className="card-title">Steven</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="b-game-card">
                        <Link to="/Brandon">
                            <div className="b-game-card__cover">
                                <img id="avis" src={brandonImg} alt="Brandon"/>
                                <h2 className="card-title">Brandon</h2>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>*/}
        </>);
    }
}