import React from 'react';
import './App.css'
import { BrowserRouter as Router,Route,Switch } from "react-router-dom";
import { Start } from "./scripts/start"
import { Home } from "./scripts/home"
import { Store } from "./scripts/store"
import Trips from "./scripts/trips"
import { Radio } from "./scripts/radio"
import { AboutUs } from "./scripts/about-us"

import { Kaje } from "./scripts/users/Kaje"
import { Mark } from "./scripts/users/Mark"
import { Jose } from "./scripts/users/Jose"
import { Nick } from "./scripts/users/Nick"
import { Steven } from "./scripts/users/Steven"
import { Brandon } from "./scripts/users/Brandon"

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    }
  }

  render () {
    return (
      <div className="background">
        <div className="layer">
          <Router>
            <Switch>
              <Route exact path="/" ><Start /></Route>
              <Route exact path="/home" ><Home /></Route>
              <Route exact path="/store" ><Store /></Route>
              <Route exact path="/trips" ><Trips /></Route>
              <Route exact path="/radio" ><Radio /></Route>
              <Route exact path="/about-us" ><AboutUs /></Route>

              <Route exact path="/Kaje"><Kaje /></Route>
              <Route exact path="/kaje"><Kaje /></Route>
              <Route exact path="/Mark"><Mark /></Route>
              <Route exact path="/mark"><Mark /></Route>
              <Route exact path="/Jose"><Jose /></Route>
              <Route exact path="/jose"><Jose /></Route>
              <Route exact path="/Nick"><Nick /></Route>
              <Route exact path="/nick"><Nick /></Route>
              <Route exact path="/Steven"><Steven /></Route>
              <Route exact path="/steven"><Steven /></Route>
              <Route exact path="/Brandon"><Brandon /></Route>
              <Route exact path="/brandon"><Brandon /></Route>
            </Switch>
          </Router>
        </div>
      </div>
    )
  }
}

export default App