import React from 'react';
import '../style/radio.css';
import { Link } from "react-router-dom";
import fambino from '../media/img/logo.png';
import radios from '../media/audio/radio1.wav';

export class Radio extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume = 0.2;
          audioEl.play()
     }

     render() {
          return( <>
               <div>
                    <audio className="audio-element" autoPlay>
                         <source src={radios}></source>
                    </audio>
               </div>
               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                   </div>
	          </div>
               <div>
                    <br></br>

                    <div className="container-md" id="container-md">
                         {/* ---- Option 1: Large Style ---- */}
                         <iframe width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" title="SoundCloud" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1093631203&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                         <div style={{fontsize: "10px",color: "#cccccc",linebreak: "anywhere",wordbreak: "normal",overflow: "hidden",whitespace: "nowrap",textoverflow: "ellipsis", fontfamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",fontweight: "100"}}>
                              <a href="https://soundcloud.com/minchello" title="Minchello - LARGE" target="_blank" rel="noreferrer" style={{color: "#cccccc", textdecoration: "none"}}>Minchello</a> · <a href="https://soundcloud.com/minchello/famraydeeo" title="FAMRAYDEEO" target="_blank" rel="noreferrer" style={{color: "#cccccc", textdecoration: "none"}}>FAMRAYDEEO</a>
                         </div>
                    </div>
               </div>
          </>);
     }
}