import React from 'react';
import '../style/store.css';
import { Link } from "react-router-dom";
import cash from '../media/audio/cash.mp3';
import fambino from '../media/img/logo.png';
import store from '../media/audio/store-page.mp3';
import storeImg from '../media/gifs/store.gif';

export class Store extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume = 0.2;
          audioEl.play()
     }

     render() {
          return( <>
               <div>
                    <audio className="audio-element">
                         <source src={cash}></source>
                    </audio>
                    <audio className="audio-element" autoPlay loop>
                         <source src={store}></source>
                    </audio>
               </div>
               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
	          </div>
               <div className="p-4 container-md">
                    <div className="w3-container w3-center">
                         <img src={storeImg} className="w3-image w3-center" style={{width:'90vh'}} alt="Merch"/>
                         <br />
                         <div className="container-md" id="container-md">
                              <h2 style={{color: 'white', backgroundColor: "rgba(0,0,0, 0.5)", boxShadow: '0 6px 12px 12px rgba(0,0,0, 0.4)', fontFamily: "'Permanent Marker', cursive"}}>Price : $666</h2>
                         </div>
                    </div>
               </div>
          </>);
     }
}