import React from 'react';
import '../style/home.css'
import { Link } from "react-router-dom";
import cap from '../media/gifs/store-main.gif';
import vhs from '../media/gifs/vhs-camera.gif'
import anthem from '../media/audio/butterfly.mp3';
import fambino from '../media/img/logo.png';
import testing from '../media/gifs/testing-home.gif';
import questionblock from '../media/gifs/question-block-red.gif';


export class Home extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume=0.2;
          audioEl.play();
     }

     render() {
          return( <>
               <div>
                    <audio id="ayo" className="audio-element" autoPlay loop>
                         <source src={anthem} type="audio/mp3"></source>
                    </audio>
               </div>
               <div className="navbars" style={{boxShadow: 'none'}}>
                    <div className="container-md">
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
	          </div>
               <div className="p-4 container-md" style={{marginTop: '-70px'}}>
                    <form className="mb-1 w-100 mx-auto mb-auto">
                         <div className="row">
                              <div className = "p-4 card-deck row align-items-center">
                                   <div className="container col-6 col-md-3">
                                        <div className="p-1 card border-0">
                                             <Link to="/store">
                                                  <div className="imgBx"><img src={cap}  alt="..."/></div>
                                             </Link>
                                             <div className="contentBx"><h2 style={{color: 'white', fontFamily: "'Permanent Marker', cursive"}}>Store</h2></div>
                                        </div>
                                   </div>
                                   <div className="container col-6 col-md-3">
                                        <div className="p-1 card border-0">
                                             <Link to="/radio">
                                                  <div className="imgBx"><img src={testing}  alt="..." /></div>
                                             </Link>
                                             <div className="contentBx"><h2 style={{color: 'white', fontFamily: "'Permanent Marker', cursive"}}>Radio</h2></div>
                                        </div>
                                   </div>
                                   <div className="container col-6 col-md-3">
                                        <div className="p-1 card border-0">
                                             <Link to="/trips">
                                                  <div className="imgBx"><img src={vhs}  alt="..."/></div>
                                             </Link>
                                             <div className="contentBx"><h2 style={{color: 'white', fontFamily: "'Permanent Marker', cursive"}}>Trips</h2></div>
                                        </div>
                                   </div>
                                   <div className="container col-6 col-md-3">
                                        <div className="p-1 card border-0">
                                             <Link to="/about-us">
                                                  <div className="imgBx"><img src={questionblock} alt="..."/></div>
                                             </Link>
                                             <div className="contentBx"><h2 style={{color: 'white', fontFamily: "'Permanent Marker', cursive"}}>About Us</h2></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </form>
               </div>
          </>
        );
    }
}