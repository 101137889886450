/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://xnmx4art3zdvpm3nwfnv7yuxgi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nm73muerobepnobzavade3v6oa",
    "aws_cognito_identity_pool_id": "us-east-2:cb55456e-b5f6-467a-98d3-3db1dae95b49",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_4RUgNYLxb",
    "aws_user_pools_web_client_id": "6s8s96kqatl4n4rhjn645nqh98",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fambino6751838e4ba9459c83c349a3626c5522141110-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
