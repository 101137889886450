import React from 'react';
import './users.css';
import { Link } from "react-router-dom";
import arrow from '../../media/gifs/arrow.gif';
import fambino from '../../media/img/logo.png';
import mark from '../../media/audio/mark.mp3';
import markImg from '../../media/pfp/mark-avi.jpg';
//import markpage from '../../media/audio/mark-page.mp3';

export class Mark extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume = 0.2;
          audioEl.play()
     }

     render() {
          return( <>
               <div>
                    {/*<audio id="audioEl" className="audio-element" autoPlay>
                         <source src={markpage} type="audio/mp3"></source>
                    </audio>*/}
                    <audio id="audioEl2" className="audio-element" autoPlay loop>
                         <source src={mark} type="audio/mp3"></source>
                    </audio>
               </div>
               
               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
                    <div className="w3-btn w3-transparent">
                         <Link to="/about-us">
                              <img src={arrow} style={{width: '50%'}} alt="Return"/>
                         </Link>
                    </div>
	          </div>

               <div className="p-4 container-md">
                    <div className="w3-container w3-center">
                         <img src={markImg} className="w3-image w3-center" style={{width:'100vh'}} alt="Mark"/>
                    </div>

                    <br></br>

                    <div className="p-1 container-md">
                         <div id="layer-description">
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Name:</u></b> Mark Minchello</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Age:</u></b> 24</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Origin:</u></b> Dallas, Texas</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Aka:</u></b> Grizzly, Grizz, Skid Mark, 720 King</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Known for:</u></b> Making this shit, EBT, Driving an absolutely bonkers corvette, Ecco2k enjoyer, The testing suit</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Quotes:</u></b> "Y'all are suck", "Odyssey these nuts in your mouth", "YUH", "Bro im vibrating","Bro ive hit 1080s"</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Instagram:</u></b> <a href="https://instagram.com/FatGoats">@FatGoats</a></div>
                         </div>
                    </div>
               </div>
          </>);
     }
}