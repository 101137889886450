import React from 'react';
import './users.css';
import { Link } from "react-router-dom";
import fambino from '../../media/img/logo.png';
import arrow from '../../media/gifs/arrow.gif';
import jose from '../../media/audio/jose.mp3'
import joseImg from '../../media/pfp/jose-avi.jpg';
import josepage from '../../media/audio/jose-page.mp3'

export class Jose extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume = 0.2;
          audioEl.play()
     }


     render() {
          return( <>

               <div>
                    <audio id="audioEl" className="audio-element" autoPlay>
                         <source src={josepage} type="audio/mp3"></source>
                    </audio>
                    <audio id="audioEl2" className="audio-element" autoPlay loop>
                         <source src={jose} type="audio/mp3"></source>
                    </audio>
               </div>

               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
                    <div className="w3-btn w3-transparent">
                         <Link to="/about-us">
                              <img src={arrow} style={{width: '50%'}} alt="Return"/>
                         </Link>
                    </div>
	          </div>

               <div className="p-4 container-md">
                    <div className="w3-container w3-center">
                              <img src={joseImg} className="w3-image w3-center" style={{width:'50vh'}} alt="Jose"/>
                    </div>

                    <br></br>

                    <div className="p-1 container-md">
                         <div id="layer-description">
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Name:</u></b> Jose "The NPC" Martinez</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Age:</u></b> 22</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Origin:</u></b> Phoenix, Arizona</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Aka:</u></b> Smiles, Smilean, The Bean, The NPC</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Known for:</u></b> Being rarted, Hittin the bars, Mindlessly wandering, Sending my fender to the gulag</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Quotes:</u></b> "Oh Jeezhus", "Bro what you doing? Grab all this shit!", "Bro, she still here?!"</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Instagram:</u></b> <a href="https://instagram.com/ogys">@ogys</a></div>
                         </div>
                    </div>
               </div>
          </>);
     }
}