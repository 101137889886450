import React from 'react';
import './users.css';
import { Link } from "react-router-dom";
import arrow from '../../media/gifs/arrow.gif';
import fambino from '../../media/img/logo.png';
import kaje from '../../media/audio/kaje.mp3';
import kajeImg from '../../media/pfp/kaje-avi.gif';
import kajepage from '../../media/audio/kaje-page.mp3'

export class Kaje extends React.Component {

     componentDidMount() {
          const audioEl = document.getElementsByClassName("audio-element")[0]
          audioEl.volume = 0.2;
          audioEl.play()
     }

     render() {
          return( <>
               <div>
                    <audio id="audioEl" className="audio-element" autoPlay>
                         <source src={kajepage} type="audio/mp3"></source>
                    </audio>
                    <audio id="audioEl2" className="audio-element" autoPlay loop>
                         <source src={kaje} type="audio/mp3"></source>
                    </audio>
               </div>

               <div className="navbars">
                    <div className="p-6 container-md" style={{marginTop: '0px'}}>
                         <form className="mb-1 w-100 mx-auto mb-auto">
                              <header className="p-1 container-md" id="containered">
                                   <Link to="/home">
                                        <img id="fambino" src={fambino} alt="fambino"/>
                                   </Link>
                              </header>
                         </form>
                    </div>
                    <div className="w3-btn w3-transparent">
                         <Link to="/about-us">
                              <img src={arrow} style={{width: '50%'}} alt="Return"/>
                         </Link>
                    </div>
	          </div>

               <div className="p-4 container-md">
                    <div className="w3-container w3-center">
                         <img src={kajeImg} className="w3-image w3-center" style={{width:'50vh'}} Alt="Kaje"/>
                    </div>

                    <br></br>

                    <div className="p-1 container-md">
                         <div id="layer-description">
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Name:</u></b> Kaje "The Mad Capper" Fortney</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Age:</u></b> 25</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Origin:</u></b> Kansas</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Aka:</u></b> Unrelative, Fartney,</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Known for:</u></b> The Drip, Being a graveyard goblin, Clearing out, Grailed, Mad Capping, Oreos, Being level 400 on Phas somehow</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Quotes:</u></b> "What the Fock?", "Im on Blackout", "YEAAAAA", "While you playing with your butt buddies ill be playing with FaZe", "Yea bro im ordering my PC soon"</div>
                              <div className="c"><b style={{color: 'white', fontSize: '1.5rem'}}><u>Instagram:</u></b> <a href="https://instagram.com/_Fortney">@_Fortney</a></div>
                         </div>
                    </div>
               </div>
          </>);
     }
}